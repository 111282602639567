<template>
  <div style="background-color: #bed3d6" class="sb-amenities-slider-container">
    <client-only>
      <slick
        ref="sb_amenities_slider"
        id="sb-amenities-slider"
        :options="sb_amenities_slickOptions"
        class="slick-for"
        v-scroll-reveal="{ origin: 'bottom' }"
      >
        <b-container fluid v-for="(slide, i) in slider" :key="i">
          <div
            class="max-w-800 mx-auto d-md-flex flex-column justify-content-center"
          >
            <div
              class="position-relative d-flex flex-column justify-content-center align-items-center h-100"
            >
              <img
                :src="slide.img"
                :alt="slide.alt"
                class="max-w-360 d-block mx-auto"
              />
              <p
                class="text-overlay text-center freight-big-pro-book-italic large-text"
              >
                {{ slide.heading }}
              </p>
            </div>
            <div class="text-center mt-4 text-blue">
              <p class="text-subheading freight-big-pro-medium sub-md-text">
                {{ slide.subheading }}
              </p>
              <p
                class="text-desc mt-3 max-w-480 mx-auto acumin-pro-wide-light sub-text"
              >
                {{ slide.desc }}
              </p>
            </div>
            <div class="d-flex align-items-center max-w-200 w-100 mx-auto mt-3">
              <img
                src="/img/sb-amenities/prev-arrow.png"
                alt="Previous"
                class="mt-2 max-w-40 max-h-20 d-inline-block mr-auto"
                role="button"
                @click="goPrevSlide()"
              />
              <div class="text-blue slide-number freight-big-pro-medium">
                <span> {{ slide.number }}/5 </span>
              </div>
              <img
                src="/img/sb-amenities/next-arrow.png"
                alt="Previous"
                class="mt-2 max-w-40 max-h-20 d-inline-block ml-auto"
                role="button"
                @click="goNextSlide()"
              />
            </div>
          </div>
        </b-container>
      </slick>
    </client-only>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderText: null,
      sb_amenities_slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: false,
        infinite: true,
        lazyLoad: "ondemand",
        vertical: false,
        verticalSwiping: false,
        asNavFor: null,
        autoplay: true,
        fade: true,
        // appendArrows: null,
        // appendDots: null,
        autoplaySpeed: 4000,
        adaptiveHeight: false,
      },
      slider: [
        {
          number: 1,
          img: "/img/sb-amenities/gallery-03.jpg",
          heading: "NATURE",
          subheading: "SET YOUR SIGHTS ON FOUR-SEASON LIVING",
          desc: "Experience the serene waters of the Georgian Bay, hike the trails of the untouched forested landscape, stroll the banks of the Nottawasaga River, and feel at ease in nature’s ambience, that is entirely yours.",
        },
        {
          number: 2,
          img: "/img/sb-amenities/gallery-02.jpg",
          heading: "LEISURE & ACTIVITY",
          subheading: "SET YOUR SIGHTS ON WAYS TO RELAX AND UNWIND",
          desc: "Swim at The Beaches – The world’s longest fresh water beach. Play a round at Marlwood Golf and Lamont Creek Tennis Club, try rafting at The Oxbow, or engage in wellness activities at the local YMCA.",
        },
        {
          number: 3,
          img: "/img/sb-amenities/gallery-04.jpg",
          heading: "SHOPPING & DINING",
          subheading: "SET YOUR SIGHTS ON DISTINCT SHOPPING & DINING",
          desc: "Get tasty treats or embrace retail therapy along Mosley Street, at Stonebridge Town Centre or Mosley Centre – with a plethora of shopping and dining options. Get everyday essentials at Walmart Superstore or Foodland which boasts 24-hour service.",
        },
        {
          number: 4,
          img: "/img/sb-amenities/gallery-06.jpg",
          heading: "SCHOOLS & ENTERTAINMENT",
          subheading: "SET YOUR SIGHTS WHERE LEARNING MATTERS",
          desc: "From Worsley Elementary to St. Noel Chabanel Catholic School to First Path Montessori Preschool and more, combined with excellent academics, sports and extracurricular activities to ensure your children's mental and physical development.",
        },
        {
          number: 5,
          img: "/img/sb-amenities/gallery-05.jpg",
          heading: "HWYS & TRANSITS",
          subheading: "SET YOUR SIGHTS ON CONVENIENCE & CONNECTIONS",
          desc: "Everywhere you want to go and where you haven’t been. By GO Transit, Hwys 10, 26 & 400; explore Collingwood, Barrie, Orillia, Vaughan, and beyond.",
        },
      ],
    };
  },
  methods: {
    goNextSlide(index) {
      this.$refs["sb_amenities_slider"].$el.slick.slickNext();
      // this.sliderText = gsap.from('.slider-text', {
      //     duration: 0.4,
      //     ease: 'Power1.inOut',
      //     y: 100,
      //     opacity: 0,
      //     delay: 0.25
      // })
    },
    goPrevSlide(index) {
      this.$refs["sb_amenities_slider"].$el.slick.slickPrev();
      // this.sliderText = gsap.from('.slider-text', {
      //     duration: 0.4,
      //     ease: 'Power1.inOut',
      //     y: 100,
      //     opacity: 0,
      //     delay: 0.25
      // })
    },
  },
};
</script>

<style lang="scss">
.sb-amenities-slider-container {
    position: relative;
  .x-line {
    position: absolute;
    width: 100%;
    // height: 85vh;
    @media screen and (max-width: 567px) {
        display: none;
      }
  }
  #sb-amenities-slider {
    padding: 100px 0px 100px 0px;
    .text-overlay {
      color: #fff;
      position: absolute;
      font-size: 5rem;
      line-height: 110%;
      @media screen and (max-width: 991px) {
        position: relative;
        font-size: 3rem;
        margin-top: 2rem;
        margin-bottom: 0;
      }
      @media screen and (max-width: 567px) {
        font-size: 1.75rem;
      }
    }
    .slide-number {
      font-size: 30px;
    }
  }
}
</style>
